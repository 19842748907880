import { render, staticRenderFns } from "./Index.vue?vue&type=template&id=7c395046&"
import script from "./Index.vue?vue&type=script&lang=js&"
export * from "./Index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/prod/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BContainer} from 'bootstrap-vue'
    installComponents(component, {BContainer})
    

export default component.exports